html,body{
  padding: 0;
  margin : 0;
  height : 100%;
  background-color : #F8F9FC;
  color: #041922;
  font-size   : 18px;
  font-family : system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
                "Noto Sans", "Liberation Sans", Arial, sans-serif;
}

.flex-container{
  height  : 100%;
  display : flex;
  flex-direction  : column;
  justify-content : center;
  align-items     : center;
}

.logo-container{
  display : block;
  width   : 75%;
  margin  : 0 auto 40px auto;
}
.logo-container img{
  display: block;
  width: 100%;
}
/* === FORM === */
.flex-container form{
  background-color : #fff;
  border-radius    : 20px;
  padding    : 46px 60px 16px 60px;
  box-shadow : 0 40px 40px 20px #dce3ef63
}
.flex-container .form-group label {
  color : #6d7c83;
  font-weight : normal;
}
.flex-container form .btn.btn-primary,
.flex-container form .form-control {
  font-size : 18px;
  padding   : 12px 6px;
  height    : auto;
  border    : 1px solid #e1e4e6;
  outline   : none !important;
  border-radius : 6px;
  box-shadow : none !important;
  transition-duration: .2s;
}
.flex-container form .btn.btn-primary.signing{
  cursor: not-allowed;
  opacity : .4;
}
.flex-container form .form-control:focus {
  border-color: #13beb7;
}
.flex-container form .btn.btn-primary:hover{
  background-color: #10aca6;
}
.flex-container form .btn.btn-primary {
  background-color : #13beb7;
  color  : #fff;
  border : none;
  font-weight : bold;
  display : block;
  width   : 100%;
  margin  : 30px 0 0 0;
}
.flex-container .group-submit{
  padding-bottom : 30px;
  position : relative
}
.form-control:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff8a inset;
}
/* FORM ERROR */
.flex-container .group-submit .warning-info{
  position   : absolute;
  text-align : center;
  width : 100%;
}
.flex-container form .warning-info{
  display   : none;
  color     : #da2a1d;
  font-size : .9em;
}
.flex-container form .has-error .warning-info{
  display : inline-block;
}
.flex-container form .warning-info > .fa{
  margin: 0 4px 0 6px;
  font-size: .9em;
}
.flex-container .form-group.has-error .form-control {
  border-color: #da2a1d;
}
#errorInfo {
  display    : none;
  text-align : center;
  margin-top : 6px;
}
#errorInfo.has-error{
  display : block;
}
/* === LINKS === */
.links {
  display : flex;
  margin-top : 70px;
  justify-content : center;
  align-items     : center;
}
.links > a {
  padding : 4px 18px;
  color   : #041922;
  border-right : 1px solid #ccc;
  text-decoration : none !important;
}
.links > a:last-child {
  border-right : none;
}
.links > a > span {
  transition-duration: .2s;
  padding-bottom: 2px;
  border-bottom: 2px solid transparent;
}
.links > a:hover > span{
  border-color: #13beb7;
}

/* === VERSION === */
.version{
  display : flex;
  margin-top : 45px;
  margin-bottom : 20px;
  justify-content : center;
  color: #8d9ba1;
  cursor: default;
}

/* === INSTALLATION COMPLETE === */
.success-info{
  background-color: #ddfcf2;
  padding: 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  font-size: 1.1em;
  color: #098681;
  margin-bottom: 20px;
}
.success-info > .fa{
  font-size : 1.6em;
  color     : #13beb7;
  margin-right : 16px;
}
.group-cmd{
  display : flex;
  align-items   : center;
  margin-bottom : 30px
}
.group-cmd .cmd-text{
  flex : 1;
  font: .8em Consolas, monospace;
  background-color: #edf0f7;
  border-radius: 6px;
  padding: 12px;
  color: #36474E;
}
.group-cmd .cmd-text + .btn{
  background-color: transparent;
  font-size  : 18px;
  min-width  : 45px;
  outline    : none !important;
  box-shadow : none !important;
}
.group-cmd .cmd-text + .btn > .fa-clipboard{
  transition-property : opacity;
  transition-duration : .2s;
  color: #36474E;
  opacity: .6;
}
.group-cmd .cmd-text + .btn:hover > .fa-clipboard{
  opacity: 1;
}
.group-cmd .cmd-text + .btn > .fa-check{
  color: #13beb7;
}